/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper, Plyr) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;


        /*
        |
        | Cookie Manager
        |-----------------
        */
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        /* Video Youtube */
        const $customPlyrVideo = jQuery('.custom-plyr-video');
        $.each($customPlyrVideo, function () {
            const $video = $(this);
            const $videoPoster = $video.find('.item-poster');
            const $videoIcon = $video.find('.item-play-icon');
            const videoId = $video.data('id');
            const type = $video.data('type');
            let player = new Plyr(`#${videoId}`);
            $(`#${videoId}`).addClass('custom-plyr-video');

            player.on("play", (event) => {
                var delayInMilliseconds = 800;
                setTimeout(function () {
                    jQuery('.item-poster').css('opacity', '0');
                }, delayInMilliseconds);
            });
            player.on("pause", (event) => {
                jQuery('.item-poster').css('opacity', '1');
            });

            $(".closeModal").click(function () {
                player.pause();
                jQuery('.item-poster').css('opacity', '1');
            });

            $(".modal").on('hidden.bs.modal', function (e) {
                player.pause();
                jQuery('.item-poster').css('opacity', '1');
            });
        });

        $(document).on('click', '.closeModal, .modal', function (event) {
            var modal = $(this).closest('.modal');
            if ($(event.target).hasClass('modal') || $(event.target).hasClass('closeModal')) {
                modal.find('iframe').each(function () {
                    $(this).attr('src', $(this).attr('src'));
                });
                modal.modal('hide');
            }
        });
        
        /* Swiper flexible */
        const swiperFlex = new Swiper('.swiper-flex', {
            centeredSlides: true,
            loop: true,
            speed: 500,
            slidesPerView: 2.5,
            spaceBetween: 40,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                30: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1.5
                },
                992: {
                    slidesPerView: 2
                }
            }
        });

        /* Anim number */
        jQuery(document).ready(function () {
            var a = 0;

            if ($('.chiffre').length) {
                $(window).scroll(function () {
                    var oTop = $('.chiffre').offset().top - window.innerHeight;

                    if (a == 0 && $(window).scrollTop() > oTop) {
                        $('.counterUp').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            },
                                {
                                    duration: 2000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(Math.floor(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(this.countNum);
                                        //alert('finished');
                                    }

                                });
                        });
                        a = 1;
                    }
                });
            }
        });

        jQuery(".btn-menu").click(function () {
            $("#header").toggleClass("active");
        });

        /* Scroll menu */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 1) {
                $('#header').addClass('active');
            } else {
                $('#header').removeClass('active');
            }
        });

        jQuery(".btn-menu").click(function () {
            $('body').toggleClass("o-h");
        });


        $body.on('loaderEnd', () => console.log('ended'))
    }
}
