/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        

        /* 
        * Select AJax
        */
        
        // variables
        let typeValue = "all";
        let styleValue = "all";
        let dateValue = $('#selectDate').val();
        let xhr = null;

        // Container de nos ressources
        const $ressourcesContainer = $('.ressources__wrapper');

        // Lorsqu'un select subit un changement
        $('.select__ressources').on('change', function(e){

            //affectation des valeurs selon l'ID du select 
            let selectId = $(this).attr('id');
            
            if(selectId === "selectType"){
                typeValue = $(this).val();
            } else if(selectId === "selectStyle") {
                styleValue = $(this).val();
            } else if(selectId === "selectDate") {
                dateValue = $(this).val();
            }

            //Abort la requête ajax
            abort(xhr);

            // url : matching avec une méthode d'un de nos controller (ajax-routes.yml)
            let url = `/ajax/ressources/`;

            //requête ajax avec l'ensemble de nos datas
            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {
                    types: typeValue,
                    style: styleValue,
                    date: dateValue
                },
                beforeSend: () => {
                    $('.loading').show();
                },
                success: (response, status) => {
                    
                    //on remplace le contenu html de notre div avec la réponse AJAX
                    $ressourcesContainer.html(response);
                    xhr = null;

                    $('.loading').hide();

                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
            
        });

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }


    }
}
