/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import Scrollbar from 'smooth-scrollbar';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import Plyr from '@components/plyr.min.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';
import ressources from '@pages/ressources.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, Menu, Kira, CustomEase]
  },
  {
    'file': main,
    'dependencies': [app, CookieManager, Swiper, Plyr]
  },
  {
    'file': news,
    'dependencies': [app],
    'resolve': '#page-news-archive'
  },
  {
    'file': contact,
    'dependencies': [app, MaterializeForm, customGoogleMap],
    'resolve': '#page-contact'
  },
  {
    'file': ressources,
    'dependencies': [app],
    'resolve': '#page-ressources'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

